@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Darker+Grotesque:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');

@media only screen and (max-height: 540px) {
  :root{
    --scaling-height: 1vh;
  }
}

@media only screen and (min-height: 540px) {
  :root{
    --scaling-height: 5.4px;
  }
}


/* Sections

- General Classes
- Utils
- Navbar
- Footer
- Home Page
- Content Sidebar
- Content Page Wrappers
- Video Player
- Back and Next Buttons
- Intro Animation
- Listen Content
- Audio Player
- VU Meter
- Intro Animation

*/

/* || General Classes */

body {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: black;
  margin: 0;
}

.App {
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: auto;
  /* position: absolute; */
  /* width: 1140px; 1140px (Original: 760px)
  height: 810px; 810px (Original: 540px) */
  background-color: #b7001e;
  aspect-ratio: 1.4;
}

@media only screen and (max-height: 540px) {
  .App {
    width: calc(calc(100 * var(--scaling-height)) * 1.4);
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-height: 540px) {
  .App {
    width: calc(540px*1.4);
  }
}

.page-wrapper {
  height: 77%;
  width: 100%;
  overflow: hidden;
  display: inline-flex;
  box-shadow: inset 0px calc(calc(0.2 * var(--scaling-height)) * 1.4) black;
}

.page-sidebar {
  position: relative;
  width: 35%;
  margin: 0;

  background-color: black;
  color: white;
  
  padding-bottom: 0;
  padding-left: 2%;
  padding-right: auto;
}

.page-content {
  width: 65%;
  margin: 0;
}

.main-content-invis {
  box-shadow: none;
}

/* || Utils */
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* || Navbar */

/* Navbar Container */
.navbar{
  display: flex;
  column-gap: 10%;
  height: 11%;
  margin: 0 5% 0.5% 38%;
  padding-top: 1%;
  /* margin-left: */
}

.invisible-navbar{
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
  padding: 0;
  border: none;
}

/* Navbar Item */
.navbar-item{
  display: inline-flex;
  height: 100%;
  width: 30%;
}

.navbar-selected-item{
  cursor: default;
}

.navbar-item:hover {
  cursor: pointer;
}

/* Navbar Note */
.navbar-note{
  height: 80%;
  width: auto;
  margin: 0;
  padding: 0;
  margin-right: -5%;
  filter: brightness(0%);
}

.navbar-note-selected{
  filter: brightness(100%);
  cursor: default;
}

.navbar-item:hover .navbar-note{
  filter: brightness(100%);
}

.navbar-item:active .navbar-note{
  transform: rotate(20deg);
}

/* Navbar Text */
.navbar-text{
  color: black;
  font-weight: bolder;
  align-self: center;

  letter-spacing: 0.175em;
  font-size: calc(calc(2.75 * var(--scaling-height)) * 1.4);

  margin-left: -5%;
  margin-top: 5%;
}

.navbar-text-selected{
  color: white;
  cursor: default;
}

.navbar-item:hover .navbar-text {
  color: white;
}

/* || Footer */

.footer{
  height: auto;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.footer-logo{
  width: 23%;
  float: right;
  margin-top: -3.5%;
  margin-bottom: 0;
}

/* || Home Page */

.home-page-sidebar {
  padding-top: 25%;
  padding-right: 1%;
}

.home-page-content{
  padding-left: 12%;
  padding-right: 2%;
  padding-top: 25%;
}

.home-logo {
  height: 90%;
  width: auto;
  margin-bottom: 5%;
}

/* Contact Block */
.contact-info{
  font-weight: bold;
  letter-spacing: 0.2em;
  line-height: 0.35em;
  font-size: calc(calc(1.6 * var(--scaling-height)) * 1.4);
}

.red-text{
  color: rgb(255, 10, 0);
}

.blue-text{
  color: rgb(1, 255, 255);
}

/* || Content Sidebar */

/* General */
.content-sidebar{
  padding-top: 7.5%;
  padding-right: 3%;
  width: 40%;
}

.content-sidebar-hr{
  border: calc(calc(0.15 * var(--scaling-height))*1.4) solid rgb(102, 1, 24);
  width: 100%;
  margin: 0;
  padding: 0;
  margin-top: 5%;
  margin-bottom: 17.5%;

  -webkit-animation-duraction: 0.5;
  animation-fill-mode: forwards;
  transform: scale(0, 0);
  animation-duration: 0.75s;
  animation-delay: 0.25s;
  animation-name: line-open;
}

/* Link Containers */
.unselected-sidebar-content-link, .selected-sidebar-content-link {
  display: flex;
  align-items: center;
  margin-bottom: 5%;

  -webkit-animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-duration: 0.5s;
  animation-name: slide-in-up;
  opacity: 0%;
}

.selected-sidebar-content-link {
  cursor: default;
}

/* Text */
.content-sidebar-text, .content-sidebar-text-selected, .content-sidebar-title, .content-sidebar-title-selected {
  margin: 0;
  padding: 0;
  margin-left: 1.05%;
  text-decoration: none;
  font-weight: bold;
  font-size: calc(calc(1.95 * var(--scaling-height))*1.4);
  vertical-align: middle;
  letter-spacing: 0.05em;
  
}

.content-sidebar-text, .content-sidebar-title{
  color: rgb(155 155 155);
}

.content-sidebar-text, .content-sidebar-text-selected{
  color: rgb(155 155 155);
  margin-bottom: 9%;
}

.content-sidebar-text-selected,.content-sidebar-title-selected{
  color: white;
  cursor: default;
}

.content-sidebar-subtitle{
  font-size: calc(calc(1.75 * var(--scaling-height))*1.4);
}

.unselected-sidebar-content-link:hover .content-sidebar-text, .unselected-sidebar-content-link:hover .content-sidebar-title {
  color: white
}

.unselected-sidebar-content-link:active .content-sidebar-text, .unselected-sidebar-content-link:active .content-sidebar-title {
  color: #b7001e;
}

/* Note */
.content-sidebar-note, .content-sidebar-note-selected{
  height: 50%;
  width: 15%;
  margin-left: -6.5%;
  margin-bottom: 5%;
  color: black;
}

.content-sidebar-note-selected{
  cursor: default
}

.unselected-sidebar-content-link:hover .content-sidebar-note {
  filter: invert(20%) sepia(100%) saturate(2684%) hue-rotate(333deg) brightness(76%) contrast(118%);
}

.unselected-sidebar-content-link:active .content-sidebar-note{
  filter: invert(20%) sepia(100%) saturate(2684%) hue-rotate(333deg) brightness(76%) contrast(118%);
  transform: rotate(20deg);
}

/* View Sidebar Animation Delays */
.view-general-reel{
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

.view-contemporary{
  -webkit-animation-delay: 1.05s;
  animation-delay: 1.05s;
}

.view-humor{
  -webkit-animation-delay: 1.2s;
  animation-delay: 1.2s;
}

.view-moods{
  -webkit-animation-delay: 1.35s;
  animation-delay: 1.35s;
}

.view-orchestral{
  -webkit-animation-delay: 1.5s;
  animation-delay: 1.5s;
}

.view-spanish{
  -webkit-animation-delay: 1.65s;
  animation-delay: 1.65s;
}

/* Listen Sidebar Animation Delays */
.listen-grooves{
  animation-delay: 1.05s
}

.listen-jazz{
  animation-delay: 1.3s
}

.listen-orchestral{
  animation-delay: 1.45s
}

.listen-ethereal{
  animation-delay: 1.6s
}

.listen-alternative{
  animation-delay: 1.75s
}

.listen-vocals{
  animation-delay: 1.9s
}

.listen-good{
  animation-delay: 2.05s
}

.listen-quirky{
  animation-delay: 2.3s
}

.listen-world{
  animation-delay: 2.45s
}

.listen-childrens{
  animation-delay: 2.6s
}

/* || Content Page Wrappers */
.content-content{
  width: 100%;
}

.content-content-title{
  color: white;
  font-weight: 400;
  
  margin: 0;
  padding: 0;
  padding-left: 5%;
  margin-top: 5.75%;

  letter-spacing: 0.12em;
  font-size: calc(calc(4.5 * var(--scaling-height)) * 1.4);
}

.content-content-line{
  margin: 0;
  padding: 0;
  opacity: 70%;
}

.category-reel{
  padding: 0;
  margin: 2% 2.5% 1% 2.5%;
  height: 75%;
  
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

/* || Video Player */

/* General */
.player{
  margin: 0;
  padding: 0;
  padding-top: 2%;
  height: 100%;
  width: 100%;

  display: inline-flex;
  flex-direction: column;
  justify-content: start;

  align-items: center;
}

.vid-container{
  padding: 0;
  margin: 0;
  border-radius: 5%;
  margin-top: -5%;

  width: 70%;
  height: 80%;
  position: relative;
  background-color: black;
  box-shadow: 0.4em 0.4em 0.75em black;
}

#extra-player-margin{
  margin-top: 5%;
}

.video-player{
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  border-radius: 5%;
}

@keyframes fade-in{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* Video Caption */
.player-text-container{
  margin: 0;
  padding: 0;

  margin-top: 3%;
  text-align: center;
  align-self: center;
}

.player-text {
  padding: 0;
  margin: 0;

  color: white;
  font-weight: bold;
  font-size: calc(calc(1.75 * var(--scaling-height)) * 1.4);
}

/* || Back and Next Buttons */

.button-note{
  margin: 0;
  margin-top: -21%;
  margin-right: -15%;
  padding: 0;

  height: 110%;
  filter: brightness(0%);
}

.button-text{
  margin: 0;
  font-weight: bolder;
  height: 90%;
  color: black;
  
  letter-spacing: 0.15em;
  font-size: calc(calc(1.75 * var(--scaling-height)) * 1.4);
}

.back-button, .next-button{
  margin: 0;
  padding: 0;
  height: 10%;
  display: flex;
}

.back-button {
  align-self: flex-end;
}

.next-button {
  align-self: flex-start;
  margin-top: -4%;
}

.back-button:hover .button-text, .next-button:hover .button-text {
  color: white;
}

.back-button:hover .button-note, .next-button:hover .button-note{
  filter: brightness(100%);
  cursor: pointer;
}

.back-button:active .button-note, .next-button:active .button-note{
  transform: rotate(20deg);
}

.back-button:hover, .next-button:hover {
  color: white;
  cursor: pointer;
}

/* || Listen Content */

.audio-list{
  margin: 3% auto 0 10%;
  height: 100%;
  width: 100%;
  list-style-type: none;

  padding: 0;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
  column-gap: 5%;
  row-gap: 5%;

  content: "";
  clear: both;
  
  z-index: 0;
  
  font-size: calc(calc(2 * var(--scaling-height))*1.4);
}

.childrens-summary {
  color: white;
  font-size: calc(calc(1.7 * var(--scaling-height))*1.4);
  font-weight: bold;
  line-height: calc(calc(2.55 * var(--scaling-height)) * 1.4);
}

.play_pause{
  height: 80px;
  position: absolute;
  margin-top: 250px;
  margin-left: 10px;
}

.half-audio-list{
  margin: 0;
  height: 100%;
  width: 100%;
  list-style-type: none;

  padding: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;

  content: "";
  clear: both;
}

.split-audio-list{
  margin: 0;
  height: 100%;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  list-style-type: none;

  padding: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;

  content: "";
  clear: both;
  
  z-index: 0;
  
  font-size: calc(calc(2 * var(--scaling-height))*1.4);
}

#thick-audio-list{
  margin: 2px;
}

.audio-list:after{
  content: "";
  clear: both;
  display: table;
}

/* || Audio Player */
.audio-title-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  height: 10%;
}

.audio-back-button{
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  align-self: center;
  padding-top: 5%;
}

.audio-title{
  margin: 0;
  padding: 0;

  color: rgb(160, 160, 160);
  font-weight: bold;
  font-size: calc(calc(3 * var(--scaling-height)) * 1.4);
}

.audio-control-container {
  height: 20%;
  margin-top: 2%;
}

.audio-control-button {
  height: 100%;
  width: 100%;
}

/* || VU Meter */
.vu-meter-container {
  margin-top: 5%;
  width: 80%;
  height: 70%;
  display: flex;
  justify-content: center;
}

/* || Pre Intro Animation */

.pre-intro-container{
  height: calc(100 * var(--scaling-height));
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pre-intro-content{
  padding: 0;
  margin: 0;
  margin-top: 20%;
  margin-left: auto;
  margin-right: auto;
  height: 45%;

}

.pre-intro-text{
  font-size: calc(calc(8.5 * var(--scaling-height)) * 1.4);
  letter-spacing: calc(calc(0.5 * var(--scaling-height)) * 1.4);
}

.pre-intro-note{
  height: 65%;
}

.start-intro-animation{
  animation-fill-mode: forwards;
  animation-name: fade-out-left;
  animation-duration: 3s;
  filter: brightness(0%);
}

.start-intro-animation:hover .button-text {
  color: black;

}

.start-intro-animation:hover .button-note{
  filter: brightness(0%);
  cursor: default;
}

.start-intro-animation:active .button-note {
  transform: rotate(0);
}

.start-intro-animation:hover{
  color: black;
  cursor: default;
}
@keyframes fade-out-left{
  33%{
    opacity: 0;
  }

  100%{
    transform: translate(-800px, 0px);
    opacity: 0;
  }
}


/* || Intro Animation */
.intro-animation{
  margin: 0;
  padding: 0;
  height: calc(100 * var(--scaling-height));
  width: 100%;
}

.intro-page-wrapper{
  box-shadow: none;
  position: relative;
}

/* Sidebar */
.intro-sidebar{
  width: 35%;
  padding: 25% 1% 0% 2%;
  position: relative;

  right: 35%;

  transform: scaleY(0.5%);
  
  animation-fill-mode: forwards;
  animation-timing-function: linear;

  animation-duration: 1.5s;
  animation-delay: 0.1s;
  
  animation-name: intro-sidebar-expand;
  
}
@keyframes intro-sidebar-expand {
  0%{
    right: 35%;
    transform: scaleY(0.5%);
  }

  50%{
    right: 0;
    transform: scaleY(0.5%);
  }

  100%{
    right: 0;
    transform: scaleY(100%);
  }
}

.intro-contact{
  opacity: 0;

  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  animation-delay: 4.85s;
  animation-name: fade-in;
}
@keyframes fade-in {
  0%{
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
}

/* Navbar */
.navbar-line{
    height: 0;
    width: 0;
    opacity: 0;
    border: calc(calc(0.1 * var(--scaling-height)) * 1.4) solid black;

    position: absolute;
    top: 0;
    left: 32%;

    animation: expand-intro-line;
    animation-fill-mode: forwards;
    animation-duration: 0.6s;
    animation-name: expand-intro-line;
    animation-timing-function: linear;
    animation-delay: 1.6s;
}
@keyframes expand-intro-line {
  0%{
    width: 0%;
    opacity: 1;
  }

  100%{
    width: 100%;
    opacity: 1;
  }
}

.intro-home, .intro-view, .intro-listen{
  transform: translateY(-100%);

  animation-fill-mode: forwards;
  animation-duration: 0.4s;
  animation-timing-function: linear;
  animation-name: fly-down;
}
@keyframes fly-down{
  0%{
    transform: translateY(-100%);
  }

  100%{
    transform: translateY(0);
  }
}

.intro-home{
  animation-delay: 4.6s
}

.intro-view{
  animation-delay: 4.8s
}

.intro-listen{
  animation-delay: 5s
}

/* Logo/Title */
.intro-logo-container{
  position: relative;
  height: 90%;
  width: 94.3%;
  max-height: 90%;
  max-width: 94.3%;

  margin: 0;
  padding: 0;
  gap: 0;
}

.intro-letter{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0%;
  
  animation-fill-mode: forwards;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-name: intro-letter-slide-in;
}
@keyframes intro-letter-slide-in {
  0%{
    opacity: 0%;
  }

  50%{
    opacity: 1;
  }

  100%{
    transform: translateX(0);
    opacity: 1;
  }
}

.intro-letter-p{
  transform: translateX(-42%);

  clip-path: polygon(0% 25%, 18% 25%, 18% 90%, 0% 90%);
  animation-delay: 2.25s;
}

.intro-letter-f1{
  transform: translateX(-55%);

  clip-path: polygon(18% 25%, 30% 25%, 30% 90%, 18% 90%);
  animation-delay: 2.4s;
}

.intro-letter-e1{
  transform: translateX(-69%);

  clip-path: polygon(30% 25%, 45% 25%, 45% 80%, 30% 80%);
  animation-delay: 2.55s;
}

.intro-letter-i{
  transform: translateX(-79%);

  clip-path: polygon(45% 45%, 55% 45%, 55% 80%, 45% 80%);
  animation-delay: 2.7s;
}

.intro-letter-f2{
  transform: translateX(-94%);

  clip-path: polygon(58% 25%, 70% 25%, 70% 80%, 58% 80%);
  animation-delay: 2.85s;
}

.intro-letter-e2{
  transform: translateX(-109%);

  clip-path: polygon(70% 25%, 85% 25%, 85% 80%, 70% 80%);
  animation-delay: 3s;
}

.intro-letter-r{
  transform: translateX(-124%);

  clip-path: polygon(85% 25%, 100% 25%, 100% 80%, 85% 80%);
  animation-delay: 3.15s;
}

.intro-letter-note{
  clip-path: circle(0% at 51% 37%);
  transition: 0.4s;
  opacity: 1;

  animation-fill-mode: forwards;
  animation-duration: 0.3s;
  animation-delay: 3.7s;
  animation-timing-function: linear;
  animation-name: intro-note-popin;
}
@keyframes intro-note-popin {
  0%{
    clip-path: circle(0% at 51% 37%);
  }

  100%{
    opacity: 1;
    clip-path: circle(30% at 51% 37%);
  }
}

.intro-music-partners{
  animation-fill-mode: forwards;
  animation-duration: 0.25s;
  animation-timing-function: linear;
  animation-delay: 4s;
  animation-name: fade-in;
  clip-path: polygon(25% 80%, 100% 80%, 100% 100%, 25% 100%);
}


/* END */


/* End of Intro Animation */


/* View Animations */

@-webkit-keyframes slide-in-up {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@keyframes slide-in-up{
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  70% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

@-webkit-keyframes line-open {
  0% {
    transform: scale(0, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

@keyframes line-open {
  0% {
    transform: scale(0, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}

/* End of View Animations */
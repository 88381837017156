/* || Containers */
.control-row{
    position: absolute;
    margin: 0;
    bottom: 0;
    /* margin-bottom: 8%; */
    height: 8%;
    width: 100%;
    
    overflow: hidden;
    opacity: 75%;
    /* margin-top: -8%; */
    /* border-bottom-left-radius: 5%;
    border-bottom-right-radius: 5%; */
    border-radius: 0 0 calc(var(--scaling-height)*1.4) calc(var(--scaling-height)*1.4);
}

.control-row-hidden{
    height: 100%;
    width: 95%;
    position: absolute;
    top: 100%;

    padding-left: 2.5%;
    padding-right: 2.5%;

    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 0 0 calc(calc(2 * var(--scaling-height))*1.4) calc(calc(2 * var(--scaling-height))*1.4);

    transition: all .3s ease-out;
    /* margin-top: 10%; */
    background: grey;
}

.control-row:hover > .control-row-hidden{
    top: 0;
}

/* || Control Buttons */
.control-row-icons {
    height: 100%;
    width: 15%;
    display: flex;
    justify-content: start;
    align-items: center;
}

.control-play-pause, .control-restart{
    margin: 0;
    width: 35%;
}

.control-restart {
    margin-right: 10%;
    margin-left: 2.5%;
}

/* || Progress Bar */
.progress-bar{
    border-radius: 500px !important;
    background-color: white;
    width: 85%;
    height: 45%;
    justify-self: end;
    margin: 0;
}

.progress-bar-filled{
    background-color: #3a3a3a;
    border-radius: 500px 0 0 500px;
    height: 100%;
}

/* 
.progress-bar.bg-actual {
    background: #b445f5;
    border-radius: 0;
}
  
.progress-bar.bg-projected {
    background: blue;
    position: relative;
}
.progress-bar.bg-projected:before {
    width: 30px;
    height: 30px;
    border-radius:0 500px 500px 0;
    background-color: #b445f5;    
    display: inline-block;
    vertical-align: middle;
    content: '';
} */
/* || Mini Reel Classes */

.mini-reel{
  margin: 1.5% 5% 1.5% 5%;
  /* padding: 1% 0 1% 0; */
  
  width: 15%;
  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 15%;
  color: black;

  opacity: 0;
  animation-fill-mode: forwards;
}

.mini-reel:hover{
  opacity: 0.5;
  cursor: pointer;
}

.mini-reel-screencap{
  /* box-sizing: border-box; */
  width: 110%;
  height: auto;
  border-radius: 12%;
  box-shadow: 0.15em 0.15em 0.5em black;
  margin-bottom: 5%;
}

.mini-reel-text{
  margin: 0;
  height: auto;
  width: 250%;

  margin-top: -1%;
  margin-bottom: -1%;

  color: white;
  font-weight: bolder;
  text-align: center;


  font-size: calc(calc(1.5 * var(--scaling-height)) * 1.4);
  
  text-shadow: 0.15em 0.15em 0.5em black;
}
/* Animations */

@-webkit-keyframes mini-reel-animation {
    0% {
      opacity: 0;
      transform: translateY(50%);
    }
    60% {
      transform: translateY(0%);
      opacity: 0.95;
    }
    100% {
      opacity: 1;
    }
}
  
@keyframes mini-reel-animation {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  60% {
    transform: translateY(0%);
    opacity: 0.95;
  }
  100% {
    opacity: 1;
  }
}

/* Mini Audio Classes */
.mini-audio {
  font-weight: bold;
  line-height: 2em;
  opacity: 0;
  animation-fill-mode: forwards;
}

.mini-audio:hover{
  color: white;
  cursor: pointer;
}

.childrens-mini-audio {
  font-weight: bold;
  line-height: 23px;
  /* margin: 5px; */
}

.childrens-mini-audio:hover{
  color: white;
  cursor: pointer;
}

/* Audio Title Animations */
@-webkit-keyframes audio-title-animation {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  60% {
    transform: translateY(0%);
    opacity: 0.95;
  }
  100% {
    opacity: 1;
  }
}

@keyframes audio-title-animation {
0% {
  opacity: 0;
  transform: translateY(50%);
}
60% {
  transform: translateY(0%);
  opacity: 0.95;
}
100% {
  opacity: 1;
}
}